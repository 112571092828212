import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './service_components/ToggleColorMode';
import { useThemeMode } from './App';
import ProfilePicture from './components/ProfilePicture';
import { useNavigate } from 'react-router-dom';

function AppAppBar({ currentUser, handleSignOut, profilePicUrl, isLandingPage = false }) {
  const [open, setOpen] = React.useState(false);
  const { mode, toggleColorMode } = useThemeMode();
  const logoStyle = {  width: '140px',  height: 'auto',  cursor: 'pointer' };

  const toggleDrawer = (newOpen) => () => { setOpen(newOpen); };
  const navigate = useNavigate();
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <AppBar position="fixed" sx={{boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2}} >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexShrink: 0, borderRadius: '999px',
              bgcolor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)', maxHeight: '60px', minHeight: '60px', border: '1px solid', borderColor: 'divider', 
              boxShadow: theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}>

            {/* Nav bar */}
            <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center' }} >
                { isLandingPage ? (
                  <Box onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} style={{ cursor: 'pointer' }} >
                    <img src={require(`${mode === 'light' ? './pics/logo0.png' : './pics/logo0_inverted.png'}`)} style={logoStyle} alt="logo of abunda" /> </Box>
                ) : (
                  <Link to="/"> <img src={require(`${mode === 'light' ? './pics/logo0.png' : './pics/logo0_inverted.png'}`)} style={logoStyle} alt="logo of abunda" /> </Link>
                )}
          
              {currentUser ? ( 
                <MenuItem component={Link} to="/home"> <Typography variant="body2" color="text.primary"> Home </Typography> </MenuItem>
                ) : (
                isLandingPage && <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <MenuItem onClick={() => scrollToSection('features')} sx={{ py: '6px', px: '12px' }} > <Typography variant="body2" color="text.primary"> Features </Typography> </MenuItem>
                <MenuItem onClick={() => scrollToSection('highlights')} sx={{ py: '6px', px: '12px' }} > <Typography variant="body2" color="text.primary"> Highlights </Typography> </MenuItem>
                <MenuItem onClick={() => scrollToSection('pricing')} sx={{ py: '6px', px: '12px' }} > <Typography variant="body2" color="text.primary"> Pricing </Typography> </MenuItem>
                <MenuItem onClick={() => scrollToSection('faq')} sx={{ py: '6px', px: '12px' }} > <Typography variant="body2" color="text.primary"> FAQ </Typography> </MenuItem>
                </Box>
              )}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 0.5, alignItems: 'center' }} >
              {currentUser ? ( <>
                <Link to="/profile" style={{ textDecoration: 'none' }}> <ProfilePicture size={40} profileUrl={profilePicUrl} /> </Link>
                <Link to="/profile" style={{ textDecoration: 'none' }}> <Typography variant="body2" sx={{ mr: 4, ml: 1, color: "text.primary" }}> {currentUser.displayName || ''} </Typography> </Link>
                <Button color="primary" variant="text" size="small" onClick={handleSignOut} > Sign out </Button>
              </> ) : ( <>
                <Button color="primary" variant="text" size="small" component={Link} to="/signin" > Sign in </Button>
                <Button color="primary" variant="text" size="small" component={Link} to="/signup" > Sign up </Button>
              </> )}
            </Box>
            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />

            {/* Drawer */}
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button variant="text" color="primary" aria-label="menu" onClick={toggleDrawer(true)} sx={{ minWidth: '30px', p: '4px' }} > <MenuIcon /> </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box sx={{minWidth: '60dvw', p: 2, backgroundColor: 'background.paper', flexGrow: 1 }}>
                  {currentUser ? ( <>
                    <MenuItem> <Button color="primary" variant="contained" component={Link} to="/home" sx={{ width: '100%' }} > Home </Button> </MenuItem>
                    <MenuItem> <Button color="primary" variant="contained" sx={{ width: '100%' }}
                            onClick={() => {navigate('/profile/settings'); setOpen(false);}} > Profile {'>'} Settings </Button> </MenuItem>
                    <MenuItem> <Button color="primary" variant="contained" sx={{ width: '100%' }}
                            onClick={() => {navigate('/profile/socials'); setOpen(false);}} > Profile {'>'} Socials </Button> </MenuItem>
                    <MenuItem> <Button color="primary" variant="contained" sx={{ width: '100%' }}
                            onClick={() => {navigate('/profile/myData'); setOpen(false);}} > Profile {'>'} My Data </Button> </MenuItem>
                    <MenuItem> <Button color="primary" variant="contained" sx={{ width: '100%' }}
                            onClick={() => {navigate('/profile/invites'); setOpen(false);}} > Profile {'>'} Invites </Button> </MenuItem>
                   </> ) : ( <>
                    <MenuItem onClick={() => scrollToSection('features')}><Typography>Features</Typography></MenuItem>
                    <MenuItem onClick={() => scrollToSection('highlights')}><Typography>Highlights</Typography></MenuItem>
                    <MenuItem onClick={() => scrollToSection('pricing')}><Typography>Pricing</Typography></MenuItem>
                    <MenuItem onClick={() => scrollToSection('faq')}><Typography>FAQ</Typography></MenuItem></>
                  )}
                  <Divider />
                  {currentUser ? ( <>
                    <Link to="/profile" style={{ textDecoration: 'none' }}>  <MenuItem sx={{ display: 'flex', alignItems: 'center' }}>
                      <ProfilePicture size={40} profileUrl={currentUser.photoURL} />
                      <Typography variant="body2" sx={{ mr: 4, ml: 1, color: "text.primary" }}>{currentUser.displayName || 'User'}</Typography> </MenuItem> </Link>
                    <MenuItem> <Button color="primary" variant="contained" onClick={handleSignOut} sx={{ width: '100%' }} > Sign Out </Button> </MenuItem>
                    </> ) : ( <>
                    <MenuItem> <Button color="primary" variant="contained" component={Link} to="/signup" sx={{ width: '100%' }} > Sign up </Button> </MenuItem>
                    <MenuItem> <Button color="primary" variant="contained" component={Link} to="/signin" sx={{ width: '100%' }} > Sign in </Button> </MenuItem>
                  </> )}  
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  currentUser: PropTypes.object
};

export default AppAppBar;
