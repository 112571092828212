import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
  IconButton, Divider, Grid, Typography, Box, Button 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';

// Displays full mission details in a dialog with action buttons including Accept for creators
const MissionDetailsDialog = ({ open, mission, userRole, onClose, onUpdate, onViewCreators, onAccept }) => {
  const navigate = useNavigate(); // Navigation hook
  if (!mission) return null;
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title" maxWidth="xl" fullWidth={false}>
      <DialogTitle id="dialog-title">
        Mission ID: {mission.mission_id}
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DialogContentText>Mission details:</DialogContentText>
            <Typography variant="body1"><b>Aim of the Mission:</b> <span style={{ marginLeft: 8 }}>{mission.aim_of_the_mission}</span></Typography>
            <Typography variant="body1"><b>Description:</b> <span style={{ marginLeft: 8 }}>{mission.mission_description}</span></Typography>
            {mission.publish_time && (
              <Typography variant="body1"><b>Publish Time:</b> <span>{new Date(mission.publish_time).toLocaleString()}</span></Typography>
            )}
            {mission.expiration_time_timer && (
              <Typography variant="body1"><b>Expiration Time:</b> <span>{new Date(mission.expiration_time_timer).toLocaleString()}</span></Typography>
            )}
            {userRole === 'brand' && mission.status === 'in_progress' && (
              <Typography variant="body1"><b>Chosen creator:</b> {mission.chosen_creator}</Typography>
            )}
            {mission.mission_description_long && (
              <Typography variant="body1"><b>Detailed Description:</b> <span style={{ marginLeft: 8 }}>{mission.mission_description_long}</span></Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <DialogContentText>Additional Information:</DialogContentText>
            {mission.creator_profile && (
              <Typography variant="body1"><b>Creator Profile:</b> <span style={{ marginLeft: 8 }}>{mission.creator_profile}</span></Typography>
            )}
            <Typography variant="body1"><b>Duration of Task:</b> <span style={{ marginLeft: 8 }}>{mission.duration_of_task ? `${mission.duration_of_task / (24 * 60 * 60)} days` : 'N/A'}</span></Typography>
            {mission.reward_type === 'lump_sum' && (
              <Typography variant="body1"><b>Reward:</b> <span style={{ marginLeft: 8 }}>{mission.total_sum?.toLocaleString(undefined, { maximumFractionDigits: 0 })} €</span></Typography>
            )}
            {mission.reward_type === 'lump_sum' && mission.advance_sum > 0 && (
              <Typography variant="body1"><b>Reward advance:</b> <span style={{ marginLeft: 8 }}>{mission.advance_sum?.toLocaleString(undefined, { maximumFractionDigits: 0 })} €</span></Typography>
            )}
            {mission.reward_type === 'percent_sales' && (
              <Typography variant="body1"><b>Percent Value of Sales:</b> <span style={{ marginLeft: 8 }}>{mission.percent_value_of_sales?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })} %</span></Typography>
            )}
            {mission.reward_type === 'barter' && (
              <Typography variant="body1"><b>Barter Description:</b> <span style={{ marginLeft: 8 }}>{mission.barter_description}</span></Typography>
            )}
            {mission.reward && (
              <Typography variant="body1"><b>Reward description:</b> <span style={{ marginLeft: 8 }}>{mission.reward}</span></Typography>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" sx={{ mr: 2 }}><b>Socials:</b></Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {mission.socials.map(s => (
                  <Box key={s}>
                    {s === 'YouTube' && <YouTubeIcon />}
                    {s === 'Twitter' && <TwitterIcon />}
                    {s === 'Instagram' && <InstagramIcon />}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" sx={{ mr: 2 }}><b>Content:</b></Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {mission.content_types.map(t => (
                  <Box key={t}>
                    {t === 'Text' && <TextFieldsIcon />}
                    {t === 'Images' && <ImageIcon />}
                    {t === 'Video' && <VideocamIcon />}
                  </Box>
                ))}
              </Box>
            </Box>
            {/* Accept button for creators when mission is published */}
            {userRole === 'creator' && mission.status === 'published' && (
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" color="primary" onClick={() => onAccept(mission.mission_id)} disabled={mission.is_accepted}>
                  {mission.is_accepted ? 'Accepted' : 'Accept'}
                </Button>
              </Box>
            )}
            {userRole === 'brand' && mission.status === 'published' && (
              <Box sx={{ mt: 2 }}>
                <DialogContentText>
                  {`${mission.acceptances.length} creator${mission.acceptances.length !== 1 ? 's' : ''} ha${mission.acceptances.length !== 1 ? 've' : 's'} accepted your mission`}
                </DialogContentText>
                {mission.acceptances.length !== 0 && (
                  <Button variant="contained" color="primary" onClick={onViewCreators}>
                    View Creators
                  </Button>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {userRole === 'brand' && mission.status === 'draft' && (
          <>
            <Button onClick={() => { onUpdate({ mission_id: mission.mission_id, status: 'deleted' }); onClose(); }} color="error">Delete</Button>
            <Button onClick={() => navigate(`/mission/${mission.mission_id}`)} color="primary">Edit</Button>
          </>
        )}
        {userRole === 'brand' && mission.status === 'published' && (
          <Button onClick={() => { onUpdate({ mission_id: mission.mission_id, status: 'canceled' }); onClose(); }} color="error">Delist</Button>
        )}
        {userRole === 'brand' && mission.status === 'canceled' && (
          <>
            <Button onClick={() => { onUpdate({ mission_id: mission.mission_id, status: 'deleted' }); onClose(); }} color="error">Delete</Button>
            <Button onClick={() => { onUpdate({ mission_id: mission.mission_id, status: 'draft' }); onClose(); }} color="primary">Make draft</Button>
          </>
        )}
        {userRole === 'creator' && mission.status === 'in_progress' && (
          <Button onClick={() => { onUpdate({ mission_id: mission.mission_id, status: 'completed' }); onClose(); }} color="success">Mark completed</Button>
        )}
        {userRole === 'brand' && mission.status === 'completed' && (
          <Button onClick={() => { onUpdate({ mission_id: mission.mission_id, status: 'in_progress' }); onClose(); }} color="warning">Return to "in progress"</Button>
        )}
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MissionDetailsDialog;
