import React, { useState, useRef, useEffect } from 'react';
import { auth } from '../service_components/firebase';
import { signInWithEmailAndPassword, signOut, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { Avatar, Button, Paper, CssBaseline, TextField, FormControlLabel, Checkbox, Link as MuiLink, Grid, Box, Typography, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ThemeProvider, useTheme } from '@mui/material/styles';
//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import GOOGLE_LOGO from "../pics/Google_logo.svg";
const FB_LOGO = require('../pics/Facebook_Logo_Secondary.png');

const SignIn = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  //const { executeRecaptcha } = useGoogleReCaptcha();
  const [errorMessage, setErrorMessage] = useState('');
  const formRef = useRef(null);

  function isMobileBrowser() {
    if (navigator.userAgentData && navigator.userAgentData.mobile !== undefined) { return navigator.userAgentData.mobile; }
    return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|webOS/i.test( navigator.userAgent );
  }

  //On mount handle any redirect result
  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (result && result.user) {
          console.log("✅ [Redirect] SignIn user:", result.user);
          navigate('/home');
        }
      })
      .catch((error) => {
        console.error("Redirect sign-in error:", error);
        setErrorMessage(error.message);
      });
  }, [navigate]);

  //GOOGLE
  const googleSignIn = async () => {
    setErrorMessage('');
    const provider = new GoogleAuthProvider();
    try {
      if (isMobileBrowser()) {
        await signInWithRedirect(auth, provider);
      } else {  
        await signInWithPopup(auth, provider);
        setTimeout(() => { navigate('/profile'); }, 1000);
      }
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user' && auth.currentUser) { navigate('/profile'); return; }
      if (error.code === 'auth/account-exists-with-different-credential') { setErrorMessage(`An account already exists with a different signin method`);
    } else { setErrorMessage(error.message); }
    }
  };

  //FACEBOOK 
  const facebookSignIn = async () => {
    setErrorMessage('');
    const provider = new FacebookAuthProvider();
    try {
      if (isMobileBrowser()) {
        await signInWithRedirect(auth, provider);
      } else {
        await signInWithPopup(auth, provider);
        setTimeout(() => { navigate('/profile'); }, 1000);
      }
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user' && auth.currentUser) { navigate('/profile'); return; }
      if (error.code === 'auth/account-exists-with-different-credential') { setErrorMessage(`An account already exists with a different signin method`);
    } else { setErrorMessage(error.message); }
    }
  };

  /*const facebookSignIn = async () => {
    setErrorMessage('');
  
    // Construct the correct OAuth URL with the config_id
    const loginUrl = `https://www.facebook.com/v17.0/dialog/oauth?client_id=3880399872210302&redirect_uri=${encodeURIComponent("https://abunda.pt/__/auth/handler")}&state=some_random_string&config_id=4035399673454061&response_type=token&scope=email`;
  
    // Redirect the user to Facebook login
    window.location.href = loginUrl;
  };*/
  

  //EMAIL
  const handleSignIn = async (event) => {
    event.preventDefault();
    //check capcha
    //if (!executeRecaptcha) { setErrorMessage('Recaptcha not available'); return; }
    //const token = await executeRecaptcha('email_signin');
    //if (!token) { setErrorMessage('Failed to execute reCAPTCHA'); return; }
    if (!formRef.current) { setErrorMessage('Form not found.'); return; }

    const data = new FormData(formRef.current);
    const email = data.get('email');
    const password = data.get('password');
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (!userCredential.user.emailVerified) {
            await signOut(auth);
            navigate('/email-confirmation-waiting');
        } else {
          setTimeout(() => { navigate('/profile'); }, 1000);
        }
    } catch (error) {
      if (error.code === 'auth/invalid-credential') { setErrorMessage(`Wrong password`);
      } else { setErrorMessage(error.message); }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={false} sm={4} md={7}
          sx={{ backgroundImage: `url(${require('../pics/login.jpg')})`, backgroundRepeat: 'no-repeat',
            backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: 'cover', backgroundPosition: 'center'}} />
        
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '48px', maxWidth: '360px', width: '100%' }} >

            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> <LockOutlinedIcon /> </Avatar>
            <Typography component="h1" variant="h5"> Sign in </Typography>
            {errorMessage && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{errorMessage}</Alert>}

            {/* Social Login Buttons */}
            <Box sx={{ width: '100%', mb: 2 }}>
              <Button fullWidth variant="contained" onClick={googleSignIn}  
                sx={{ mt: 2, mb: 1, minHeight: "50px", backgroundColor: "#fff", color: "rgba(0,0,0,0.54)", textTransform: "none", fontWeight: "bold", border: "1px solid #dadce0", "&:hover": { backgroundColor: "#f7f7f7" } }}>
                <img src={GOOGLE_LOGO} alt="Google" style={{ width: 24, height: 24, marginRight: 8, verticalAlign: "middle" }} /> Sign In with Google
              </Button>
              <Button fullWidth variant="contained" onClick={facebookSignIn}  
                sx={{ mt: 1, mb: 2, minHeight: "50px", backgroundColor: "#1877F2", color: "#fff", textTransform: "none", fontWeight: "bold", "&:hover": { backgroundColor: "#166FE5" } }}>
                <img src={FB_LOGO} alt="Facebook" style={{ width: 24, height: 24, marginRight: 8, verticalAlign: "middle" }} /> Sign In with Facebook
              </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 2 }}>
              <Box sx={{ flex: 1, height: '1px', backgroundColor: 'gray' }} />
              <Typography sx={{ mx: 1 }}>or</Typography>
              <Box sx={{ flex: 1, height: '1px', backgroundColor: 'gray' }} />
            </Box>

            <Box component="form" noValidate ref={formRef} onSubmit={handleSignIn} sx={{ mt: 1 }}>
              <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
              <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
              <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, minHeight: "50px" }}  > Sign In </Button>

              <Grid container>
                <Grid item xs> <MuiLink component={Link} to="/password-reset" variant="body2"> Forgot password? </MuiLink> </Grid>
                <Grid item> <MuiLink component={Link} to="/signup" variant="body2"> Don't have an account? Sign Up </MuiLink> </Grid>
              </Grid>

            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default SignIn;
