import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import MissionCard from './MissionCard';

const MissionList = ({ missions, loading, onCardClick, onAccept, observerRef, userRole }) => (
  <Grid container spacing={0} sx={{ alignItems: 'center' }}>
    {missions.map(m => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={m.mission_id}>
        <MissionCard mission={m} userRole={userRole} onClick={() => onCardClick(m)} onAccept={onAccept} />
      </Grid>
    ))}
    {loading && (
      <Box sx={{ textAlign: 'center', width: '100%', p: 1 }}>
        <Typography variant="body1">Loading...</Typography>
      </Box>
    )}
    <div ref={observerRef} style={{ height: '1px' }} />
  </Grid>
);

export default MissionList;
