import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiCall } from '../hooks/useApiCall.js';

const OAuth2Callback = () => {
  const navigate = useNavigate();
  const callApi = useApiCall();

  useEffect(() => {
    const processCallback = async () => {
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.substring(1));
      const accessToken = params.get('access_token');
      const expiresIn = params.get('expires_in');

      if (accessToken && expiresIn) {
        const expiryTime = Date.now() + parseInt(expiresIn, 10) * 1000;
        try {
          await callApi('/api/update-profile/', { method: 'POST', requestData: { youtube_access_token: accessToken, youtube_token_expiry: new Date(expiryTime).toISOString(), }, });
          navigate('/profile/socials', { replace: true, state: { youTubeConnected: true } });
        } catch (error) { navigate('/profile/settings', { replace: true, state: { youTubeConnected: false } }); }
      } else { navigate('/profile/settings', { replace: true, state: { youTubeConnected: false } }); }
    };

    processCallback();
  }, [navigate, callApi]);

  return <div>Loading...</div>;
};

export default OAuth2Callback;
