import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const UserInfo = ({ user, size = 40, showName = true, clickable = true, style = {} }) => {
  if (!user) return null;
  const { id, username, profile_picture, role } = user;
  const route = role === 'brand' ? `/brand/${id}` : `/creator/${id}`;
  const content = (
    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, border: '1px solid', borderColor: 'grey.300', borderRadius: '9999px', backgroundColor: 'grey.100', ...style }}>
      <Avatar src={profile_picture} alt={`${username || 'User'}'s profile`} sx={{ width: size, height: size }} />
      {showName && <Typography variant="body2" sx={{ ml: 1 }}>{username}</Typography>}
    </Box>
  );
  return clickable ? <Link to={route} style={{ textDecoration: 'none' }}>{content}</Link> : content;
};

export default UserInfo;
