// usePublicUserProfile.js
import { useState, useEffect } from 'react';
import { useApiCall } from './useApiCall';

export const usePublicUserProfile = (userId) => {
  const callApi = useApiCall();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userId) return;
    (async () => {
      try {
        const data = await callApi(`/api/public-user-profile/${userId}/`, { method: 'GET' });
        setProfile(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [userId, callApi]);

  return { profile, loading, error };
};