import React, { useEffect, useState, createContext, useContext, } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { auth } from './service_components/firebase';
import { onAuthStateChanged, signOut, reload, deleteUser } from 'firebase/auth';
import SignUp from './auth/SignUp';
import Documentation from './landing/Documentation';
import ChartTest from './components/ChartTest';
import TestRedirect from './components/redirtest';
import SignIn from './auth/SignIn';
import Profile from './Profile';
import Settings from './profile/Settings';
import Socials from './profile/Socials';
import MyData from './profile/MyData';
import InviteScreen from './profile/InviteScreen';
import CreatorPage from './CreatorPage';
import BrandPage from './BrandPage';
import PasswordReset from './profile/PasswordReset';
import PrivacyPolicy from './landing/PrivacyPolicy';
import EmailConfirmationWaiting from './auth/EmailConfirmationWaiting';
import InvitePage from './auth/InvitePage';
import Home from './Home';
import Master from './missions/MissionMaster';
import LandingPage from './Landing';
import AppAppBar from './AppBar';
import Contacts from './landing/Contacts';
import OAuth2Callback from './service_components/OAuth2Callback';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LoadingScreen from './service_components/LoadingScreen.js';
import { useApiCall } from './hooks/useApiCall';

const ThemeContext = createContext();
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState('light');
  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [isInvited, setIsInvited] = useState(null);
  
  const theme = createTheme({ palette: { mode: mode }});
  const navigate = useNavigate();
  const callApi = useApiCall();
  const toggleColorMode = () => { setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await reload(user);
        setCurrentUser(user);
        try {
          const res = await callApi('/api/user-profile/?fields=invited', { method: 'GET' });
          setIsInvited(res.invited);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          setIsInvited(null);
        }
      } else {
        setCurrentUser(null);
        setIsInvited(null);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [callApi]);
  

  const handleSignOut = async () => {
    try { await signOut(auth);
      alert("Signed out successfully");
      navigate('/');
    } catch (error) { console.error("Error signing out: ", error); }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm("Are you sure you want to delete your account? This action cannot be undone")) {
      try {
        const user = auth.currentUser;

        // Check if the user signed in recently (15 minutes threshold)
        const lastSignIn = new Date(user.metadata.lastSignInTime);
        const now = new Date();
        const threshold = 15 * 60 * 1000;
        if (now - lastSignIn > threshold) { alert("Your login session is too old. Please reauthenticate and then try deleting your account"); return; }

        const uid = user.uid;
        // Delete backend data first
        await callApi('/api/delete-user/', { method: 'DELETE', requestData: { uid } });
        
        // Then delete the Firebase user
        await deleteUser(user);
        alert("Account deleted successfully.");
        navigate('/');
      } catch (error) {
        // If Firebase returns 'requires-recent-login'
        if (error.code === 'auth/requires-recent-login') {
          alert("Your session is too old. Please sign out, sign in again, and then try deleting your account.");
        } else {
          console.error("Error deleting account:", error);
          alert("Failed to delete account. Please try again or contact support.");
        }
      }
    }
  };

  function RequireVerification({ children }) {
    if (currentUser) { const providerId = currentUser.providerData[0]?.providerId;
      if (providerId === "password" && !currentUser.emailVerified) {
        console.log("Redirecting to email confirmation because email is not verified");
        return <Navigate replace to="/email-confirmation-waiting" />;}
      if (isInvited === false) {
        console.log("Redirecting to invite page because user is not invited");
        return <Navigate replace to="/invite" />;
      }
    } return children;  }

  if (loading) return (<LoadingScreen loading={true} />);

  return (
    <ThemeContext.Provider value={{ mode, toggleColorMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} currentUser={currentUser} handleSignOut={handleSignOut} profilePicUrl={profilePicUrl}/>
            <Routes>
              <Route path="/oauth2callback" element={<OAuth2Callback />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/creator/:creatorId" element={<CreatorPage />} />
              <Route path="/brand/:brandId" element={<BrandPage />} />
              <Route path="/" element={<LandingPage mode={mode} toggleColorMode={toggleColorMode}/>} />
              <Route path="/home" element={currentUser ? (<RequireVerification><Home /></RequireVerification>) : (<Navigate replace to="/" />)} />
              <Route path="/mission" element={currentUser ? (<RequireVerification><Master /></RequireVerification>) : (<Navigate replace to="/" />)} />
              <Route path="/mission/:missionId" element={ currentUser ? (<RequireVerification><Master /></RequireVerification>) : (<Navigate replace to="/" />)} />
              <Route path="/signup" element={<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}><SignUp /></GoogleReCaptchaProvider>} />
              <Route path="/signin" element={!currentUser ? <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}><SignIn /></GoogleReCaptchaProvider> : <Navigate replace to="/home" />} />
              <Route path="/profile" element={ currentUser ? (<RequireVerification><Profile onProfilePictureUpdate={setProfilePicUrl} /></RequireVerification>) : (<Navigate replace to="/" />)}>
                  <Route path="settings" element={<Settings />} />
                  <Route path="socials" element={<Socials />} />
                  <Route path="myData" element={<MyData />} />
                  <Route path="invites" element={<InviteScreen />} />
                  <Route index element={<Settings />} />
              </Route>  
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/email-confirmation-waiting" element={<EmailConfirmationWaiting handleDeleteAccount={handleDeleteAccount}/>} />
              <Route path="/invite" element={<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}><InvitePage setIsInvited={setIsInvited} handleDeleteAccount={handleDeleteAccount}/></GoogleReCaptchaProvider>} />
              <Route path="/docs" element={currentUser ? <Documentation /> : <Navigate replace to="/signin" />} />
              <Route path="/contact" element={<Contacts />} />
              <Route path="/chart-test" element={<ChartTest />} />
              <Route path="/redirtest" element={<TestRedirect />} />
            </Routes>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export function useThemeMode() { return useContext(ThemeContext); }
export default App;