import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { auth } from './service_components/firebase';
import { Container, Grid, Typography, Box, Button, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useApiCall } from './hooks/useApiCall.js'; // API helper hook
import { useApiFetch } from './hooks/useApiFetch.js';
import MissionList from './missions/MissionList'; // Renders mission cards grid
import MissionDetailsDialog from './missions/MissionDetailsDialog'; // Shows mission details dialog
import LoadingScreen from './service_components/LoadingScreen.js';

// Status priority mapping
const statusPriority = { in_progress:1, published:2, draft:3, completed:4, expired:5, deleted:6 };

const Home = () => {
  const navigate = useNavigate(); // Navigation hook
  const theme = useTheme(); // Theme hook
  const callApi = useApiCall(); // API call helper
  const { data: profile, loading: profileLoading, error: profileError } = useApiFetch('/api/user-profile/', 'GET'); // Get user profile
  const userRole = profile ? profile.role : ''; // Determine user role
  const userName = auth.currentUser?.displayName || "User"; // Get display name

  // States for missions and pagination
  const [missions, setMissions] = useState([]); // Missions list (for creators)
  const [acceptedMissions, setAcceptedMissions] = useState([]); // Accepted missions (for brands)
  const [missionFilter, setMissionFilter] = useState('Active'); // Current mission filter
  const [offset, setOffset] = useState(0); // Pagination offset
  const [hasMore, setHasMore] = useState(true); // More missions flag
  const [missionsLoading, setMissionsLoading] = useState(false); // Loading state

  // State for mission details dialog
  const [openDialog, setOpenDialog] = useState(false); // Mission details dialog open
  const [currentMission, setCurrentMission] = useState(null); // Selected mission

  const observerRef = useRef(); // Ref for IntersectionObserver target

  // IntersectionObserver callback: load more missions when visible
  const handleObserver = useCallback(entries => {
    const [entry] = entries;
    if (entry.isIntersecting && !missionsLoading && hasMore && missions.length)
      setOffset(prev => prev + 12);
  }, [missionsLoading, hasMore, missions]);

  // Set up the IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { threshold: 0 });
    if (observerRef.current) observer.observe(observerRef.current);
    return () => observer.disconnect();
  }, [handleObserver]);

  // Open the mission details dialog
  const handleCardClick = m => {
    setCurrentMission(m);
    setOpenDialog(true);
  };

  // Close the mission details dialog
  const handleDialogClose = () => setOpenDialog(false);

  // Fetch missions for creators
  const fetchMissions = useCallback(async () => {
    if (missionsLoading || !hasMore) return;
    setMissionsLoading(true);
    if (userRole === 'creator') {
      try {
        const data = await callApi(`/api/missions/list?status=${missionFilter}&limit=12&offset=${offset}`, { method: 'GET' });
        setMissions(prev => [...prev, ...data.filter(n => !prev.some(p => p.mission_id === n.mission_id))]);
        if (data.length < 12) setHasMore(false);
      } catch (e) { console.error(e); }
    }
    setMissionsLoading(false);
    // eslint-disable-next-line
  }, [userRole, missionFilter, offset, hasMore, callApi]);

  // Fetch accepted missions for brands
  const fetchAcceptedMissions = useCallback(async () => {
    if (userRole === 'brand') {
      try {
        const data = await callApi('/api/missions/list-accepted/', { method: 'GET' });
        setAcceptedMissions(data);
        return data;
      } catch (e) { console.error(e); return []; }
    }
  }, [userRole, callApi]);

  // Update mission status via API
  const updateMission = async m => {
    try {
      const res = await callApi(`/api/missions/update/${m.mission_id}/`, { method: 'POST', requestData: m });
      if (res) {
        if (m.status !== 'completed' && userRole === 'brand') {
          const missionsData = await fetchAcceptedMissions();
          const updated = missionsData.find(x => x.mission_id === m.mission_id);
          if (updated) setCurrentMission({ ...updated, ...m });
        }
        if (m.status === 'deleted') setOpenDialog(false);
        alert('Mission updated!');
      }
    } catch (e) { console.error('Failed to update mission', e); }
  };

  // Handle mission acceptance (for creators)
  const handleMissionAcceptance = useCallback(async id => {
    try {
      const res = await callApi(`/api/missions/accept/${id}/`, { method: 'POST' });
      if (res) { 
        alert('Mission accepted!');
        setMissions(prev => prev.filter(m => m.mission_id !== id));
        await fetchMissions();
      } else alert('Failed to accept mission.');
    } catch (e) { console.error(e); }
  }, [callApi, fetchMissions]);

  // Reset missions and pagination when mission filter changes
  useEffect(() => { setMissions([]); setOffset(0); setHasMore(true); }, [missionFilter]);

  // Fetch missions (or accepted missions) when dependencies change
  useEffect(() => {
    if (!profileLoading) {
      if (userRole === 'creator') fetchMissions();
      else if (userRole === 'brand') fetchAcceptedMissions();
    }
  }, [offset, userRole, missionFilter, profileLoading, fetchMissions, fetchAcceptedMissions]);

  // Memoize sorted missions for display
  const sortedMissions = useMemo(() => {
    const list = userRole === 'brand' ? acceptedMissions : missions;
    return [...list].sort((a, b) =>
      statusPriority[a.status] - statusPriority[b.status] || new Date(a.creation_time) - new Date(b.creation_time)
    );
  }, [missions, acceptedMissions, userRole]);
  
  // Handle profile loading and errors
  if (profileLoading || !profile) return (<LoadingScreen loading={true} text="Loading profile data..." />);
  if (profileError) return (<LoadingScreen loading={false} error={profileError} />);

  return (
    <ThemeProvider theme={theme}>
      {/* Header */}
      <Box id="titlebox" sx={{ width: '100%', height: '30vh', minHeight: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(rgba(206,229,253,0.2),rgba(206,229,253,0.7))' : 'linear-gradient(#033363,#021F3B)' }}>
        <Container maxWidth="lg" sx={{ height: '60%', minHeight: '120px', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          <Typography variant="h5" sx={{ mt: 2, ml: 2 }}>Welcome, {userRole} {userName}</Typography>
          <Typography variant="body1" sx={{ ml: 2 }}>This is the home page of your platform</Typography>
          {userRole === 'brand' && (
            <Button variant="outlined" sx={{ mt: 2, position: 'absolute', top: 50, right: 24, display: { xs: 'none', sm: 'inline-flex' } }} onClick={() => navigate('/mission')}>
              New Mission
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Grid container spacing={0} sx={{ ml: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Grid item xs={12}>
              <RadioGroup row name="mission_filter" value={missionFilter} onChange={e => setMissionFilter(e.target.value)} sx={{ alignItems: 'center', display: 'flex', flexWrap: 'nowrap', '& .MuiFormControlLabel-root': { transform: { xs: 'scale(0.8)', sm: 'scale(1)' } } }}>
                <FormControlLabel value="All" control={<Radio />} label="All" />
                <FormControlLabel value="Active" control={<Radio />} label="Active" />
                {userRole === 'brand' && <FormControlLabel value="Drafts" control={<Radio />} label="Drafts" />}
                <FormControlLabel value="Finished" control={<Radio />} label="Finished" />
              </RadioGroup>
            </Grid>
          </Grid>
        </Container>
        <Divider />
      </Box>

      {/* Missions List */}
      <Box sx={{ width: '100%', height: '70vh', backgroundColor: theme.palette.mode === 'light' ? "#f8f9fd" : "#1a2634" }}>
        <Container maxWidth="lg">
          <MissionList 
            missions={sortedMissions}
            loading={missionsLoading}
            onCardClick={handleCardClick}
            onAccept={handleMissionAcceptance}  // Pass acceptance callback
            observerRef={observerRef}
            userRole={userRole}
          />
        </Container>
        <Container maxWidth="lg">
          <Typography variant="body1">
            {userRole === 'brand' ? `${acceptedMissions.length} missions in your view` : `${missions.length} missions in your view`}
          </Typography>
        </Container>
      </Box>

      {/* Mission Details Dialog */}
      <MissionDetailsDialog
        open={openDialog}
        mission={currentMission}
        userRole={userRole}
        onClose={handleDialogClose}
        onUpdate={updateMission}
        onAccept={handleMissionAcceptance}
      />
    </ThemeProvider>
  );
};

export default Home;
