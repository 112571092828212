// useApiCall.js
import { useCallback } from 'react';
import { auth } from '../service_components/firebase';

export const useApiCall = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const callApi = useCallback(
    async (endpoint, opts = {}) => {
      console.log("callApi options:", {endpoint, opts});
      const { method = 'GET', requestData = null, requiresAuth = true } = opts;
      let token = null;
      if (requiresAuth) {
        if (!auth.currentUser) { throw new Error("No Firebase user is authenticated."); }
        try { token = await auth.currentUser.getIdToken();
        } catch (error) { throw new Error("Error fetching token: " + error.message); } }

      const options = { method, headers: { 'Content-Type': 'application/json' }, mode: 'cors' };
      if (requiresAuth && token) { options.headers.Authorization = `Bearer ${token}`; }
      if (requestData) { options.body = JSON.stringify(requestData); }

      const res = await fetch(`${apiUrl}${endpoint}`, options);
      if (!res.ok) {
        const errorText = await res.text();
        console.error("API call failed with status", res.status, "and error:", errorText);
        throw new Error(`API call failed: ${errorText}`);
      }
      const text = await res.text();
      console.log("Answer:", text ? JSON.parse(text) : {});
      return text ? JSON.parse(text) : {};
    }, [apiUrl]
  );

  return callApi;
};