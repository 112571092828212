// useUserProfile.js
import { useState, useEffect } from 'react';
import { useAuthToken } from './useAuthToken';

export const useUserProfile = () => {
  // Get token and auth loading state from useAuthToken.
  const { token, loading: authLoading } = useAuthToken();

  // State for profile data and its loading/error status.
  const [profile, setProfile] = useState(null);
  const [profileLoading, setProfileLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);

  // Define your API URL (ensure this is set in your environment).
  const apiUrl = process.env.REACT_APP_API_URL;

  // Once auth is complete (authLoading is false), fetch the user profile if a token is available.
  useEffect(() => {
    if (authLoading) {
      // Still waiting for authentication to finish.
      return;
    }
    if (!token) {
      // Authentication finished but no token is available.
      setProfileError(
        new Error("No Firebase token available. User might not be logged in.")
      );
      setProfileLoading(false);
      return;
    }

    // Reset profile state before fetching new data.
    setProfileLoading(true);
    setProfileError(null);
    setProfile(null);

    // Call the API to fetch the user profile.
    fetch(`${apiUrl}/api/user-profile/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      mode: 'cors',
    })
      .then(async (res) => {
        if (!res.ok) {
          const errorText = await res.text();
          throw new Error(`API call failed: ${errorText}`);
        }
        return res.json();
      })
      .then((data) => {
        setProfile(data);
      })
      .catch((err) => {
        console.error("Error fetching profile:", err);
        setProfileError(err);
      })
      .finally(() => {
        setProfileLoading(false);
      });
  }, [authLoading, token, apiUrl]);

  // Combine the loading states: we're still loading if either auth or profile is loading.
  const loading = authLoading || profileLoading;
  const error = profileError;

  return { profile, loading, error };
};
