import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { auth } from '../service_components/firebase';
import { ThemeProvider } from '@mui/material/styles';
import { deleteUser } from 'firebase/auth';
import { Typography, Box, Button, Grid } from '@mui/material';
import { useApiCall } from '../hooks/useApiCall.js';

function MyData() {
  const { theme } = useOutletContext();
  const callApi = useApiCall();

  const handleDeleteAccount = async () => {
    if (window.confirm("Are you sure you want to delete your account? This action cannot be undone")) {
      try {
        const user = auth.currentUser;

        // Check if the user signed in recently (15 minutes threshold)
        const lastSignIn = new Date(user.metadata.lastSignInTime);
        const now = new Date();
        const threshold = 15 * 60 * 1000;
        if (now - lastSignIn > threshold) { alert("Your login session is too old. Please reauthenticate and then try deleting your account"); return; }

        const uid = user.uid;
        // Delete backend data first
        await callApi('/api/delete-user/', { method: 'DELETE', requestData: { uid } });
        
        // Then delete the Firebase user
        await deleteUser(user);
        alert("Account deleted successfully.");
      } catch (error) {
        // If Firebase returns 'requires-recent-login'
        if (error.code === 'auth/requires-recent-login') {
          alert("Your session is too old. Please sign out, sign in again, and then try deleting your account.");
        } else {
          console.error("Error deleting account:", error);
          alert("Failed to delete account. Please try again or contact support.");
        }
      }
    }
  };
  

  return (
      <ThemeProvider theme={theme}>
        <Grid container sx={{ width: "100%"}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", height: "60px", mx: { xs: 1, sm: 4 }, mt: 4 }}>
                <Typography variant="h5" sx={{ fontWeight: 600 }} > My Data </Typography>
                <Typography variant="subtitle2"> <span style={{ color: theme.palette.primary.main }}>Profile</span> {'>'} My Data </Typography>
            </Box>

            <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}> Delete data </Typography>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: "100%" }}>
                    <Box sx={{ width: { md: '100%', xs: '100%' }, height: "280px", mr: 2, p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="body1"> You can completely remove your data here </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}><Button variant="contained" color="error" onClick={handleDeleteAccount} sx={{ mt: 2 }}> Delete account </Button></Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
      </ThemeProvider>
    );
}

export default MyData;