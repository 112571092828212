import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { useApiFetch } from './hooks/useApiFetch.js';
import { useApiCall } from './hooks/useApiCall.js';
import LoadingScreen from './service_components/LoadingScreen.js';

// Profile component for user profile page
export default function Profile({ onProfilePictureUpdate }) { 
    const callApi = useApiCall();
    const theme = useTheme();
    const location = useLocation();
    
    const { data, loading: profileLoading, error: profileError } = useApiFetch('/api/user-profile/', 'GET');
    const [profile, setProfile] = useState(null);

    // When profile is available, update local state.
    useEffect(() => { if (data && !profile) { setProfile(data); } }, [data, location, profile]);

    // Centralized function to update profile data.
    const updateProfileData = useCallback(async (data) => {
        try {
            const updatedData = await callApi('/api/update-profile/', { method: 'POST', requestData: data });
            // Merge new changes into the local profile state.
            setProfile((prevProfile) => ({ ...prevProfile, ...data }));
            return updatedData;
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    }, [callApi]);

    // Handle profile loading and errors
    if (profileLoading || !profile) return (<LoadingScreen loading={true} text="Loading profile data..." />);
    if (profileError) return (<LoadingScreen loading={false} error={profileError} />);
    
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ height: '100vh', width: '100%' }}>
                {/*<Box sx={{ height: '100px', width: '100%' }}></Box>*/}
                <Box sx={{ display: 'flex' }}>
                    {/* Sidebar */}
                    <Box sx={{ pt: 10, minWidth: '200px', maxWidth: '200px', minHeight: 'calc(100vh - 0px)', flexGrow: 1, flexDirection: 'column', 
                        display: { xs: 'none', md: 'flex' }, backgroundColor: "#233043", overflowY: 'auto', position: 'fixed' }}>
                        <Typography sx={{ mt: 5, mb: 2, color: '#fff', px: 5 }}> Profile </Typography>
                        <NavLink to="settings" style={{ textDecoration: 'none' }}> <Button sx={{ px: 6, color: '#fff', textTransform: 'none' }}> Settings </Button> </NavLink>
                        <NavLink to="socials" style={{ textDecoration: 'none' }}> <Button sx={{ px: 6, color: '#fff', textTransform: 'none' }}> Socials </Button> </NavLink>
                        <NavLink to="myData" style={{ textDecoration: 'none' }}> <Button sx={{ px: 6, color: '#fff', textTransform: 'none' }}> My Data </Button> </NavLink>
                        <NavLink to="invites" style={{ textDecoration: 'none' }}> <Button sx={{ px: 6, color: '#fff', textTransform: 'none' }}> Invites </Button> </NavLink>
                    </Box>

                    {/* Main content area */}
                    <Box sx={{ pt: 10, marginLeft: { xs: '0', md: '200px' }, width: { xs: '100%', md: `calc(100% - 200px)` }, minHeight: 'calc(100vh - 0px)', 
                        flexGrow: 1, backgroundColor: theme.palette.mode === 'light' ? "#f8f9fd" : "#1a2634" }}>
                            <Outlet context={{ theme, profile, updateProfileData, onProfilePictureUpdate }} />
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};