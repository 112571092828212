import React, { useState, useEffect, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box, Typography, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useApiCall } from '../hooks/useApiCall.js';
import UserInfo from '../components/UserInfo';

function InviteScreen() {
  const { theme, profile } = useOutletContext();
  const callApi = useApiCall();
  
  const [inviteCodes, setInviteCodes] = useState([]);
  const [loadingInvites, setLoadingInvites] = useState(true);
  const [inviteError, setInviteError] = useState('');
  const [referredProfiles, setReferredProfiles] = useState({});
  const activeInvites = inviteCodes.filter( invite => new Date(invite.expires_at) > new Date() && !invite.is_revoked );
  const activeCount = activeInvites.length;

  const fetchInviteCodes = useCallback(async () => {
    setLoadingInvites(true);
    setInviteError('');
    try {
      const data = await callApi('/api/invite-codes', { method: 'GET' });
      setInviteCodes(data.inviteCodes || []);
    } catch (err) { setInviteError(err.message || 'Error fetching invite codes.'); }
    setLoadingInvites(false);
  }, [callApi]);

  const createNewInvite = async () => {
    try {
      await callApi('/api/invite-codes', { method: 'POST', requestData: {} });
      fetchInviteCodes();
    } catch (err) {
      alert(err.message || 'Error creating invite code');
    }
  };

  useEffect(() => { fetchInviteCodes(); }, [fetchInviteCodes]);

  // For each used invite, fetch the referred user's public profile if not already cached
  useEffect(() => {
    inviteCodes.forEach(invite => {
      if (invite.is_used && invite.used_by) {
        // Use a functional update to check if we've already attempted fetching this profile
        setReferredProfiles(prev => { if (prev.hasOwnProperty(invite.used_by)) { return prev; }
          // Otherwise, start the fetch process.
          const userId = parseInt(invite.used_by, 10);
          callApi(`/api/public-user-profile/${userId}/`, { method: 'GET' })
            .then(profileData => { const data = profileData.message ? null : profileData; setReferredProfiles(prev2 => ({ ...prev2, [invite.used_by]: data, })); })
            .catch(err => {
              console.error(`Error fetching profile for user ${invite.used_by}:`, err);
              // Set the key to null so that we don't try to refetch
              setReferredProfiles(prev2 => ({ ...prev2, [invite.used_by]: null, }));
            });
          return prev;
        });
      }
    });
  }, [inviteCodes, callApi]);
  
  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ width: '100%' }}>
        {/* Header */}
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mx: { xs: 1, sm: 4 }, mt: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>Invites</Typography>
          <Typography variant="subtitle2" sx={{ mb: 2 }}> <span style={{ color: theme.palette.primary.main }}>Profile</span> {'>'} Invites </Typography>
          {!loadingInvites && profile && ( <>
              <Typography variant="body2" sx={{ mb: 1 }}> Active invites: {activeCount} / {profile.max_invites} </Typography>
              {activeCount < profile.max_invites ? ( <Button variant="contained" onClick={createNewInvite} sx={{ maxWidth: '200px' }}> Generate New Invite Code </Button>
              ) : ( <Typography variant="body2" color="error" sx={{ mt: 1 }}> You have reached your invite limit. </Typography> )}
            </> )}
        </Box>

        {/* Invite Codes Table */}
        <Grid item xs={12} sx={{ mx: { xs: 1, sm: 4 }, my: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>My Invite Codes</Typography>
          {loadingInvites ? (                 <Typography>Loading invite codes...</Typography>
          ) : inviteError ? (                 <Typography color="error">{inviteError}</Typography>
          ) : inviteCodes.length === 0 ? (    <Typography>You do not have invite codes. Click "Generate New Invite Code" above to create one.</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Used</TableCell>
                    <TableCell>Expires At</TableCell>
                    <TableCell>Referred</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inviteCodes.map(invite => (
                    <TableRow key={invite.code}>
                      <TableCell>{invite.code}</TableCell>
                      <TableCell>{invite.is_used ? 'Yes' : 'No'}</TableCell>
                      <TableCell>{new Date(invite.expires_at).toLocaleString()}</TableCell>
                      <TableCell>
                        {invite.is_used && invite.used_by && referredProfiles[invite.used_by] ? (
                          <UserInfo user={referredProfiles[invite.used_by]} size={32} showName={true} clickable={true} />
                        ) : ( '-' )}
                      </TableCell>
                      <TableCell>
                        {!invite.is_used && ( <Button variant="outlined" size="small" onClick={() => { 
                          const signupLink = `${window.location.origin}/signup?invite=${invite.code}`;
                          navigator.clipboard.writeText(signupLink);
                        }}> Copy </Button> )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default InviteScreen;
