import React, { useEffect, useState, useCallback  } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Typography, Switch, FormControlLabel, Box, Grid, IconButton, Tooltip, TextField} from '@mui/material';
import MuiLink from '@mui/material/Link';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import RefreshIcon from '@mui/icons-material/Refresh';
//import AIScraper from '../components/AIScraper';
//import MetricsVisualizer from '../components/MetricsVisualizer';

const redirectUri = process.env.REACT_APP_OAUTH_CALLBACK_URL;

function Socials() {
  const { theme, profile, updateProfileData } = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  
  // Website state and handlers
  const [isWebsiteEnabled, setWebsiteEnabled] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isUrlValid, setIsUrlValid] = useState(true);
  const handleUrlChange = (event) => {
    const newUrl = event.target.value;
    setWebsiteUrl(newUrl);
    const isValidUrl = (url) => { 
      try { new URL(url); return true; } catch (e) { return false; } 
    };
    setIsUrlValid(isValidUrl(newUrl) || newUrl === '');
  };

  const handleWebsiteToggle = async () => { 
    if (isUrlValid && websiteUrl !== '' && !isWebsiteEnabled) { 
      setWebsiteEnabled(true); 
      await updateProfileData({ my_website: websiteUrl }); 
    } else if (isWebsiteEnabled) {
      setWebsiteEnabled(false);
      await updateProfileData({ my_website: null }); 
    } 
  };

  // Instagram state and handlers
  const [isInstaConnected, setIsInstaConnected] = useState(false);
  const [instaData, setInstaData] = useState({ channelId: '' });
  const handleInstaChange = (event) => { setInstaData({ channelId: event.target.value }); };
  
  const handleInstaToggle = async () => { 
    if (instaData.channelId !== '' && !isInstaConnected) { 
      setIsInstaConnected(true); 
      await updateProfileData({ instagram_channelId: instaData.channelId }); 
    } else if (isInstaConnected) {
      setIsInstaConnected(false);
      await updateProfileData({ instagram_channelId: null }); 
    }
  };

  // YouTube handlers
  const [isYouTubeConnected, setYouTubeConnected] = useState(false);

  useEffect(() => {
    // Website: enable if profile.my_website exists
    setWebsiteUrl(profile?.my_website || '');
    setWebsiteEnabled(!!profile?.my_website);
        
    // Instagram: enable if instagram_channelId exists
    setInstaData({ channelId: profile?.instagram_channelId || '' });
    setIsInstaConnected(!!profile?.instagram_channelId);
        
    // YouTube: consider connected if youtube_channelId exists
    setYouTubeConnected(!!profile?.youtube_channelId);
  }, [profile]);
  
  const handleYouTubeToggle = async () => { if (!isYouTubeConnected) { handleRefreshData(); } else { clearLocalYoutubeData(); } };
  const handleConnectYouTube = () => {
    const clientId = '120976137224-1etuivd4mauq2outqr64g0g9jdmvuc72.apps.googleusercontent.com';
    const newredirectUri = encodeURIComponent(`${redirectUri}/oauth2callback`);
    const scope = encodeURIComponent('https://www.googleapis.com/auth/youtube.readonly');
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${newredirectUri}&response_type=token&scope=${scope}&include_granted_scopes=true&prompt=select_account`;
    window.location = authUrl;
  };
  
  const clearLocalYoutubeData = async () => {
    try {
      setYouTubeConnected(false);
      const resetData = { youtube_access_token: "", youtube_token_expiry: null, youtube_subscribers: 0, youtube_views: 0, youtube_video_count: 0, youtube_channelId: '' };
      await updateProfileData(resetData);
    } catch (error) { console.error('Error during the YouTube disconnection process:', error); }
  };
  
  const handleRefreshData = useCallback(async () => {
    try {
      if (!profile?.youtube_access_token) { handleConnectYouTube(); return; }
      const tokenExpiryDate = new Date(profile.youtube_token_expiry);

      if (new Date() >= tokenExpiryDate) { handleConnectYouTube(); return; }
      const youtubeResponse = await fetch('https://www.googleapis.com/youtube/v3/channels?part=statistics&mine=true', { 
        headers: { 'Authorization': `Bearer ${profile.youtube_access_token}` }
      });
      const ytData = await youtubeResponse.json();
      if (!youtubeResponse.ok) { console.error('Error fetching YouTube data:', ytData.error); return; }
      if (ytData.items && ytData.items.length > 0) {
        const { subscriberCount, videoCount, viewCount } = ytData.items[0].statistics;
        const channelId = ytData.items[0].id;
        await updateProfileData({ 
          youtube_subscribers: subscriberCount, 
          youtube_views: viewCount, 
          youtube_video_count: videoCount, 
          youtube_channelId: channelId 
        });
      }
    } catch (error) { console.error('Error during the data refresh process:', error); }
  }, [updateProfileData, profile]);
  useEffect(() => { if (location.state?.youTubeConnected) { handleRefreshData().then(() => { navigate('/profile/socials', { replace: true, state: {} }); }); }  }, [location, handleRefreshData, navigate]);
  
  // AI Metrics section state & handlers
  //const [scraperData, setScraperData] = useState(null);
  //const handleScraperData = (data) => { setScraperData(data); };

  return (
    <Grid container sx={{ width: "100%" }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", height: "60px", mx: { xs: 1, sm: 4 }, mt: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}> Socials </Typography>
        <Typography variant="subtitle2"> <span style={{ color: theme.palette.primary.main }}>Profile</span> {'>'} Socials </Typography>
      </Box>

      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', mx: { xs: 1, sm: 2 }, my: 1, p: { xs: 0, sm: 2 } }}>
        <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'justify' }}>
          Connect and activate your social accounts here by toggling the switches on.
          Abunda will only collect data related to the number of subscribers, content uploads, and views from your accounts.
          Your connected accounts will be displayed on the platform. You can disconnect and delete your data from Abunda by turning the switches off.
        </Typography>
      </Grid>

      {/* Website Section */}
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', 
        mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}> My website </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={<Switch checked={isWebsiteEnabled} onChange={handleWebsiteToggle} />}
                label={<Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}> <LanguageIcon sx={{ mr: 1 }} /> Website </Box>}
                sx={{ width: 'auto', alignItems: 'center' }} disabled={!isUrlValid || websiteUrl === ''} />
            </Grid>
            <Grid item xs={12} sm={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {isWebsiteEnabled ? (
                <MuiLink href={websiteUrl} target="_blank" rel="noopener noreferrer" underline="hover" sx={{ ml: 2 }}> {websiteUrl} </MuiLink>
              ) : (
                <TextField sx={{ mx: 2, flexGrow: 1 }} variant="outlined" fullWidth margin="dense" value={websiteUrl} placeholder="Enter your website full URL"
                  type="url" onChange={handleUrlChange} error={!isUrlValid} helperText={!isUrlValid && "Please enter a valid URL, like https://abunda.pt"} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Confirmed Social Accounts Section */}
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', 
        mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}> My confirmed social accounts </Typography>
        <Grid container spacing={2} alignItems="center">
          {/* YouTube */}
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Grid item xs={4} sm={3}>
              <FormControlLabel
                control={<Switch checked={isYouTubeConnected} onChange={handleYouTubeToggle} />}
                label={<Box sx={{ display: 'flex', alignItems: 'center' }}> <YouTubeIcon sx={{ mr: 1 }} /> YouTube </Box>}
                sx={{ width: 'auto', alignItems: 'center' }}
              />
            </Grid>
            <Grid item xs={7} sm={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {isYouTubeConnected && (
                <>
                  <Typography sx={{ mx: 2, display: { xs: 'none', sm: 'block' } }}>
                    Subscribers: <b>{profile?.youtube_subscribers}</b> | Videos: <b>{profile?.youtube_video_count}</b> | Views: <b>{profile?.youtube_views}</b>
                  </Typography>
                  <MuiLink href={`https://www.youtube.com/channel/${profile?.youtube_channelId}`} target="_blank" rel="noopener noreferrer" underline="hover"> YouTube </MuiLink>
                </>
              )}
            </Grid>
            <Grid item xs={1} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {isYouTubeConnected && (
                <Tooltip title="Refresh YouTube stats"><span><IconButton onClick={handleRefreshData} size="small" sx={{ ml: 2, color: "lightblue" }}> <RefreshIcon /> </IconButton></span></Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: 'center' }}>
            {isYouTubeConnected && (
              <Typography sx={{ mx: 2 }}> Subscribers: <b>{profile?.youtube_subscribers}</b> | Videos: <b>{profile?.youtube_video_count}</b> | Views: <b>{profile?.youtube_views}</b> </Typography>
            )}
          </Grid>

          {/* Instagram */}
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Grid item xs={4} sm={3}>
              <FormControlLabel
                control={<Switch checked={isInstaConnected} onChange={handleInstaToggle} />}
                label={<Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}> <InstagramIcon sx={{ mr: 1 }} /> Instagram </Box>}
                sx={{ width: 'auto', alignItems: 'center' }} disabled={profile?.instagram_channelId === ''} />
            </Grid>
            {isInstaConnected ? (
              <>
                <Grid item xs={7} sm={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Typography sx={{ mx: 2 }}> No data </Typography>
                  <MuiLink href={`https://www.instagram.com/${profile?.instagram_channelId}`} target="_blank" rel="noopener noreferrer" underline="hover" sx={{ ml: 2 }}>
                    {profile?.instagram_channelId}
                  </MuiLink>
                </Grid>
                <Grid item xs={1} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Tooltip title="Refresh Instagram stats"><span>
                    <IconButton onClick={() => {}} size="small" sx={{ ml: 2, color: "lightblue" }}>
                      <RefreshIcon />
                    </IconButton>
                  </span></Tooltip>
                </Grid>
              </>
            ) : (
              <Grid item xs={8} sm={9} sx={{ ml: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <TextField sx={{ mx: 2, flexGrow: 1 }} variant="outlined" fullWidth margin="dense" value={instaData.channelId} placeholder="Enter your instagram handle" onChange={handleInstaChange} />
              </Grid>
            )}
          </Grid>
        </Grid>
    </Grid>

    {/* AIScraper 
    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}> Creator's Social Profile Analysis </Typography>
        <AIScraper onData={handleScraperData}/>
    </Grid>
    */}

    {/* Metrics Visualization 
    {scraperData && (
    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
        <MetricsVisualizer data={scraperData} />
    </Grid>
    )} */}

    </Grid>

  );
}

export default Socials;