import React from 'react';
import { Card, CardActionArea, CardHeader, CardContent, CardActions, Divider, Tooltip, Box, Typography, LinearProgress, Button } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';

const MissionCard = ({ mission, userRole, onClick, onAccept }) => (
  <Card sx={{ my: 1, mx: 1, height: '300px' }}>
    {/* Clickable area for main content */}
    <CardActionArea onClick={onClick} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardHeader
        title={`Mission ${mission.mission_id}`}
        subheader={mission.mission_description}
        action={
          <Typography variant="caption" sx={{ position: 'absolute', top: 0, right: 8, p: 1, fontWeight: 'bold', fontSize: '0.6rem' }}>
            {mission.status.toUpperCase()}
          </Typography>
        }
        sx={{
          width: '100%',
          position: 'relative',
          bgcolor: () => {
            if (mission.status === 'draft') return 'rgba(211,211,211,0.3)';
            if (mission.status === 'published') return '#20BBCD';
            if (mission.status === 'in_progress') return alpha(green[500], 0.5);
            if (mission.status === 'completed') return 'rgba(0,128,0,0.3)';
            return 'rgba(211,211,211,0.8)';
          }
        }}
        subheaderTypographyProps={{
          variant: 'body2',
          sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '250px' }
        }}
      />
      {mission.status === 'published' && (
        <Tooltip title={
          <>
            Published: {mission.publish_time ? new Date(mission.publish_time).toLocaleString() : 'N/A'}<br />
            Will be delisted: {mission.expiration_time_timer ? new Date(mission.expiration_time_timer).toLocaleString() : 'N/A'}
          </>
        }>
          <Box sx={{ width: '100%', height: 4 }}>
            <LinearProgress variant="determinate" value={
              ((new Date() - new Date(mission.publish_time)) / (new Date(mission.expiration_time_timer) - new Date(mission.publish_time))) * 100
            } sx={{ height: 4 }} />
          </Box>
        </Tooltip>
      )}
      <Divider sx={{ opacity: 0.2, borderColor: 'grey.500' }} />
      <CardContent sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1"><b>Duration:</b> {mission.duration_of_task ? `${mission.duration_of_task / (24 * 60 * 60)} days` : 'N/A'}</Typography>
        {mission.reward_type === 'lump_sum' && (
          <Typography variant="body1">
            <b>Reward:</b> {Math.round(mission.total_sum).toLocaleString(undefined, { maximumFractionDigits: 0 })} €
            {mission.advance_sum > 0 ? `, adv: ${Math.round(mission.advance_sum).toLocaleString(undefined, { maximumFractionDigits: 0 })} €` : ''}
          </Typography>
        )}
        {mission.reward_type === 'percent_sales' && (
          <Typography variant="body1">
            <b>Reward:</b> {mission.percent_value_of_sales.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })} % of Sales
          </Typography>
        )}
        {mission.reward_type === 'barter' && (
          <Typography variant="body1"><b>Reward:</b> Barter</Typography>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ mr: 2 }}><b>Socials:</b></Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {mission.socials.map(s => (
              <Box key={s}>
                {s === 'YouTube' && <YouTubeIcon />}
                {s === 'Twitter' && <TwitterIcon />}
                {s === 'Instagram' && <InstagramIcon />}
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ mr: 2 }}><b>Content:</b></Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {mission.content_types.map(t => (
              <Box key={t}>
                {t === 'Text' && <TextFieldsIcon />}
                {t === 'Images' && <ImageIcon />}
                {t === 'Video' && <VideocamIcon />}
              </Box>
            ))}
          </Box>
        </Box>
        {userRole === 'creator' && <Typography variant="body1"><b>Brand:</b> {mission.brand}</Typography>}
        {userRole === 'brand' && mission.status === 'in_progress' && (
          <Typography variant="body1"><b>Chosen creator:</b> {mission.chosen_creator}</Typography>
        )}
        {userRole === 'brand' && mission.status === 'published' && (
          <Typography variant="body2" sx={{ mt: 'auto', fontSize: '0.8rem' }}>
            {mission.acceptances.length === 0
              ? "No one has yet accepted this mission"
              : `${mission.acceptances.length} creator${mission.acceptances.length !== 1 ? 's' : ''} ha${mission.acceptances.length !== 1 ? 've' : 's'} accepted your mission`}
          </Typography>
        )}
      </CardContent>
    </CardActionArea>
    {/* Action buttons */}
    <CardActions sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {userRole === 'brand' && mission.status === 'draft' && (
        <Button variant="contained" color="primary" onClick={e => { e.stopPropagation(); /* action handled in details dialog */ }} sx={{ width: '100%' }}>
          Publish
        </Button>
      )}
      {userRole === 'creator' && mission.status === 'published' && (
        <Button variant="contained" color="primary" disabled={mission.is_accepted} onClick={e => { e.stopPropagation(); onAccept(mission.mission_id); }} sx={{ width: '100%' }}>
          {mission.is_accepted ? 'Accepted' : 'Accept'}
        </Button>
      )}
    </CardActions>
  </Card>
);

export default MissionCard;
