// useAuthToken.js
import { useState, useEffect } from 'react';
import { auth } from '../service_components/firebase';
import { onAuthStateChanged } from 'firebase/auth';

// This hook listens to Firebase auth state and returns an object with the token and a loading flag
export const useAuthToken = () => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idToken = await user.getIdToken(true);
          setToken(idToken);
        } catch (error) {
          console.error("Error fetching token:", error);
          setToken(null);
        }
      } else {
        console.log("No user");
        setToken(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { token, loading };
};