import React, { useEffect } from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Contacts = () => {

    useEffect(() => {
        const contactInfo = document.getElementById("contact-info");
        if (contactInfo) {
            contactInfo.innerHTML = `
                <p><strong>Artem Gadzhiev</strong><br>
                <em>Individual Entrepreneur</em><br>
                Praceta Jose Marques Abreu 4, 2820-092 Aroeira, Portugal<br>
                Phone: +351 938877183<br>
                Email: <a href="mailto:team@abunda.pt">team@abunda.pt</a></p>`;
        }
    }, []); // Runs only once on component mount

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 15 }}>
            <Paper elevation={1} sx={{ p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Contact Us
                </Typography>
                <Typography paragraph>
                    If you have any inquiries or need assistance, feel free to contact us using the details below:
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Business Contact Information
                </Typography>
                <div id="contact-info"></div>

            </Paper>
        </Container>
    );
};

export default Contacts;
