import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import LOADING_GIF from '../pics/giphy2.webp';

const LoadingScreen = ({ loading = true, error = null, text = 'Loading...', gifUrl = true }) => {
  if (error) return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h6" color="error">{typeof error === 'string' ? error : error.message || 'An error occurred'}</Typography>
    </Container>
  );
  if (loading) return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {gifUrl && <Box component="img" src={LOADING_GIF} alt="Loading animation" sx={{ mb: 0, width: '20%', backgroundColor: 'transparent' }} />}
      <Typography variant="h6" align="center">{text}</Typography>
    </Container>
  );
  return null;
};
export default LoadingScreen;
