import React from 'react';
//import ReactDOM from 'react-dom';
import App from './App';
//import { StrictMode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
//import RedirectTestPage from './components/redirtest';

const container = document.getElementById('root');
const root = createRoot(container);

//root.render(<StrictMode> <App /> </StrictMode>);
root.render( 
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <App />    
    </Router> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals