import React, { useEffect } from 'react';
import { auth } from '../service_components/firebase';
import { GoogleAuthProvider, signInWithRedirect, getRedirectResult } from 'firebase/auth';

export default function RedirectTestPage() {
  useEffect(() => {
    getRedirectResult(auth).then((result) => {
      console.log("getRedirectResult:", result);
      console.log("auth.currentUser:", auth.currentUser);
      if (result) {
        console.log("✅ Google redirect user:", result.user);
      } else {
        console.log("⚠️ No redirect result found");
      }
    }).catch(console.error);
  }, []);

  const handleRedirectSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  return (
    <div style={{ margin: '3rem', textAlign: 'center' }}>
      <h1>Redirect Test</h1>
      <button onClick={handleRedirectSignIn}>
        Google Sign In (Redirect)
      </button>
    </div>
  );
}
