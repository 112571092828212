import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';
import Contacts from './Contacts';

const PrivacyPolicy = () => {
    const location = useLocation();

    useEffect(() => {
        const handleHash = (hash) => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop, 
                        behavior: 'smooth'
                    });
                }
            }
        };

        handleHash(location.hash);
        return () => {
            window.removeEventListener('hashchange', () => handleHash(window.location.hash));
        };
    }, [location, location.hash]); 

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 15 }}>
            <Paper elevation={1} sx={{ p: 3 }}>
                <Typography variant="h4" gutterBottom id="policy">
                    Privacy Policy
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Last updated: 23 of February 2025
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Introduction
                </Typography>
                <Typography paragraph>
                    Abunda ("we" or "us" or "our") respects the privacy of our users ("user" or "you").
                    This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you
                    visit our website abunda.pt, including any other media form, media channel, mobile website,
                    or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy
                    policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Legal Basis for Processing Personal Data (GDPR)
                </Typography>
                <List dense>
                    <ListItem><ListItemText primary="Your Consent" secondary="When you provide clear consent for us to process your data for a specific purpose." /></ListItem>
                    <ListItem><ListItemText primary="Contractual Obligation" secondary="When processing is necessary for the performance of a contract with you or to take steps at your request before entering a contract." /></ListItem>
                    <ListItem><ListItemText primary="Legal Compliance" secondary="When we are required to process your data to comply with legal obligations." /></ListItem>
                    <ListItem><ListItemText primary="Legitimate Interests" secondary="When processing is necessary for our legitimate interests, such as improving our services and preventing fraud, and does not override your rights." /></ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    Cookies & Tracking Technologies
                </Typography>
                <Typography paragraph>
                    We use cookies, tracking pixels, and similar technologies to analyze website activity and improve our services.
                    These technologies may collect information such as your IP address, browser type, and website interactions.
                </Typography>
                <Typography variant="subtitle1">Your Choices</Typography>
                <List dense>
                    <ListItem><ListItemText primary="Managing Cookies" secondary="You can control and disable cookies through your browser settings." /></ListItem>
                    <ListItem><ListItemText primary="Opting Out of Tracking" secondary="To opt out of targeted ads, visit the Network Advertising Initiative at <a href='https://www.networkadvertising.org/choices/' target='_blank'>this link</a>." /></ListItem>
                </List>
                <Typography paragraph>
                    By using our website, you consent to our use of cookies in accordance with our Cookie Policy.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Automated Decision-Making and Profiling
                </Typography>
                <Typography paragraph>
                    We may use automated decision-making processes and profiling in areas such as fraud detection, personalized marketing, and user experience optimization.
                    These processes analyze data like user behavior, preferences, and demographics. You have the right to request human intervention if you believe an automated decision affects you significantly.
                </Typography>


                <Typography variant="h6" gutterBottom>
                    Your Rights Under GDPR and CCPA
                </Typography>
                <List dense>
                    <ListItem><ListItemText primary="Right to Access" secondary="You can request access to the personal data we hold about you." /></ListItem>
                    <ListItem><ListItemText primary="Right to Rectification" secondary="You can request corrections to inaccurate or incomplete personal data." /></ListItem>
                    <ListItem><ListItemText primary="Right to Erasure (Right to Be Forgotten)" secondary="You can request that we delete your personal data, subject to certain legal obligations." /></ListItem>
                    <ListItem><ListItemText primary="Right to Restrict Processing" secondary="You can ask us to temporarily or permanently stop processing your data." /></ListItem>
                    <ListItem><ListItemText primary="Right to Object to Processing" secondary="You can object to us processing your data for marketing, profiling, or other purposes." /></ListItem>
                    <ListItem><ListItemText primary="Right to Data Portability" secondary="You can request a copy of your personal data in a machine-readable format." /></ListItem>
                    <ListItem><ListItemText primary="Right to Non-Discrimination (CCPA)" secondary="California consumers have the right to not be discriminated against for exercising their privacy rights." /></ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    How to Exercise Your Rights
                </Typography>
                <Typography paragraph>
                    If you wish to exercise any of your rights under GDPR or CCPA, you may contact us by:
                </Typography>
                <List dense>
                    <ListItem><ListItemText primary="Email" secondary="team@abunda.pt" /></ListItem>
                    <ListItem><ListItemText primary="Mailing Address" secondary="Praceta Jose Marques Abreu 4, 2820-092 Aroeira, Portugal" /></ListItem>
                </List>
                <Typography paragraph>
                    We will respond to your request within 30 days in compliance with applicable laws.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Selling or Sharing of Personal Data
                </Typography>
                <Typography paragraph>
                    We do not sell or share your personal data with third parties for monetary gain. However, we may share data with trusted service providers to improve our services, as outlined in this policy.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Data Breach Notification
                </Typography>
                <Typography paragraph>
                    In the event of a data breach that affects your personal data, we will notify you and relevant authorities as required by applicable laws.
                    We have security measures in place to protect your data, but if a breach occurs, we will take immediate action to contain and mitigate any risks.
                </Typography>


                <Typography variant="h6" gutterBottom id="delete-data">
                    How to Delete Your Data
                </Typography>
                <List dense>
                    <ListItem><ListItemText primary="Log in to your account" secondary="Visit the 'My Data' section and click 'Delete Account' to request deletion." /></ListItem>
                    <ListItem><ListItemText primary="Send us a request" secondary="Email us at team@abunda.pt with the subject 'Data Deletion Request'." /></ListItem>
                </List>
                <Typography paragraph>
                    Please note that certain data may be retained if required by law, such as for tax, fraud prevention, or legal compliance purposes.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Data Retention Policy
                </Typography>
                <Typography paragraph>
                    We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy,
                    unless a longer retention period is required or permitted by law (such as tax, accounting, or legal requirements).
                    When we no longer have a legitimate reason to process your data, we securely delete or anonymize it.
                </Typography>
                <Typography paragraph>
                    Specifically:
                </Typography>
                <List dense>
                    <ListItem><ListItemText primary="Account Data" secondary="Retained as long as your account is active. If you request deletion, data is removed within 30 days." /></ListItem>
                    <ListItem><ListItemText primary="Payment Data" secondary="Stored for tax and fraud prevention reasons for up to 7 years as required by law." /></ListItem>
                    <ListItem><ListItemText primary="Marketing Data" secondary="Stored until you opt-out or withdraw consent, after which it is deleted within 14 days." /></ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    International Data Transfers
                </Typography>
                <Typography paragraph>
                    Your personal data may be transferred to and processed in countries outside the European Economic Area (EEA),
                    including the United States, where data protection laws may differ. When we transfer your data, we ensure that
                    appropriate safeguards are in place, such as:
                </Typography>
                <List dense>
                    <ListItem><ListItemText primary="EU-U.S. Data Privacy Framework" secondary="Our partners in the U.S. comply with the EU-U.S. Data Privacy Framework where applicable." /></ListItem>
                    <ListItem><ListItemText primary="Standard Contractual Clauses (SCCs)" secondary="We use legally approved contracts to protect your data when transferring outside the EEA." /></ListItem>
                </List>
                <Typography paragraph>
                    If you would like more details on these safeguards, please contact us at <a href="mailto:team@abunda.pt">team@abunda.pt</a>.
                </Typography>


                <Contacts />
            </Paper>
        </Container>
    );
};

export default PrivacyPolicy;
