// useApiFetch.js
import { useState, useEffect } from 'react';
import { useAuthToken } from './useAuthToken';

export const useApiFetch = ( endpoint, method = 'GET', requestData = null, requiresAuth = true) => {
  const { token, loading: authLoading } = useAuthToken();

  // State for fetched data and its loading/error status.
  const [data, setData] = useState(null);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch data once auth is complete (if required) and the endpoint changes.
  useEffect(() => {
    // If authentication is required, wait for it.
    if (requiresAuth && authLoading) { return; }

    // If authentication is required but no token is present, set an error.
    if (requiresAuth && !token) {
      setFetchError(new Error("No Firebase token available. User might not be logged in."));
      setFetchLoading(false);
      return;
    }

    // Reset state before new fetch.
    setFetchLoading(true);
    setFetchError(null);
    setData(null);

    // Build the fetch options.
    const options = { method, headers: { 'Content-Type': 'application/json', }, mode: 'cors', };
    if (requiresAuth) { options.headers.Authorization = `Bearer ${token}`; }
    if (requestData) { options.body = JSON.stringify(requestData); }

    fetch(`${apiUrl}${endpoint}`, options)
      .then(async (res) => {
        if (!res.ok) {
          const errorText = await res.text();
          throw new Error(`API call failed: ${errorText}`);
        }
        return res.json();
      })
      .then((result) => { setData(result); })
      .catch((err) => { console.error("Error fetching data:", err); setFetchError(err); })
      .finally(() => { setFetchLoading(false); });

  }, [authLoading, token, apiUrl, endpoint, method, requestData, requiresAuth]);

  // Loading is true if either auth (if required) or fetch is in progress.
  const loading = (requiresAuth ? authLoading : false) || fetchLoading;
  const error = fetchError;

  return { data, loading, error };
};
