// useApi.js
import { useAuthToken } from './useAuthToken';
import { useCallback } from 'react';

// This hook returns a function that makes API calls using the token. It assumes that the caller won’t invoke it until auth is ready.
export const useApi = () => {
  const { token } = useAuthToken();
  const apiUrl = process.env.REACT_APP_API_URL;

  const callApi = useCallback(
    async (endpoint, options = {}) => {
      if (!token) {
        throw new Error("No Firebase token available. User might not be logged in.");
      }
      const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, ...options.headers, };
      const res = await fetch(`${apiUrl}${endpoint}`, { ...options, headers, mode: 'cors', });

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(`API call failed: ${errorText}`);
      }
      return res.json();
    },
    [token, apiUrl]
  );

  return callApi;
};