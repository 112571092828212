import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppAppBar from './AppBar';
import Hero from './landing/Hero';
import Highlights from './landing/Highlights';
import Pricing from './landing/Pricing';
import LogoCollection from './landing/LogoCollection';
import Features from './landing/Features';
import FAQ from './landing/FAQ';
import Footer from './landing/Footer';
import { useThemeMode } from './App';
import { ThemeProvider, useTheme } from '@mui/material/styles';

export default function LandingPage() {
  const { mode, toggleColorMode } = useThemeMode();
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} isLandingPage={true} />
      <Hero />
      <Box sx={{ bgcolor: 'background.default' }}>
        <LogoCollection/>
        <Divider />
        <Features />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
