import React, { useState, useEffect, useCallback, useRef } from 'react';
import { auth } from '../service_components/firebase';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Radio, RadioGroup, Link as MuiLink, Grid, Box, Typography, Container, Alert } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useApiCall } from '../hooks/useApiCall.js';

import GOOGLE_LOGO from "../pics/Google_logo.svg";
const FB_LOGO = require('../pics/Facebook_Logo_Secondary.png');
const AVATAR_CREATOR = require('../pics/avatar_creator.jpg');
const AVATAR_BRAND = require('../pics/avatar_brand.jpg');

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userRole, setUserRole] = useState('creator');
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showEmailSignUp, setShowEmailSignUp] = useState(false);

  //const { executeRecaptcha } = useGoogleReCaptcha();
  //const [captchaToken, setCaptchaToken] = useState(null);
  const callApi = useApiCall();
  const avatarSrc = userRole === 'creator' ? AVATAR_CREATOR : AVATAR_BRAND;

  function isMobileBrowser() {
    if (navigator.userAgentData && navigator.userAgentData.mobile !== undefined) { return navigator.userAgentData.mobile; }
    return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|webOS/i.test( navigator.userAgent );
  }

  // Handle any redirect sign-in result after the page reloads
  useEffect(() => {
    getRedirectResult(auth)
      .then(async (result) => {
        if (result && result.user) { console.log("✅ [Redirect] Google/Facebook user:", result.user);
          const user = result.user;
          try {
            // create user in our backend
            const createUserResponse = await callApi('/api/create-user', {
              method: 'POST',
              requiresAuth: false,
              requestData: {
                uid: user.uid,
                username: user.displayName,
                email: user.email,
                is_brand: userRole === 'brand',
                is_creator: userRole === 'creator'
              }
            });
            if (createUserResponse.error) { throw new Error(createUserResponse.error); }
            navigate('/invite');
          } catch (err) {
            console.error("Error in post-redirect flow:", err);
            setErrorMessage(err.message);
          }
        }
      })
      .catch((error) => {
        console.error("🔥 getRedirectResult error:", error);
        setErrorMessage(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setUserRole(searchParams.get('role') || 'creator');
  }, [location]);

  /*
  useEffect(() => {
    // Pre-fetch the reCAPTCHA token when the component mounts.
    if (executeRecaptcha) {
      executeRecaptcha('social_signup')
        .then(token => setCaptchaToken(token))
        .catch(err => console.error("reCAPTCHA execution failed", err));
    }
  }, [executeRecaptcha]); */

  //GOOGLE
  const googleSignUp = useCallback(async () => {
    //check capcha
    //if (!captchaToken) { setErrorMessage('reCAPTCHA token is not ready. Please try again.'); return; }

    setIsLoading(true); 
    setErrorMessage('');
    try {
      const provider = new GoogleAuthProvider();

      if (isMobileBrowser()) {
        await signInWithRedirect(auth, provider);
      } else {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        await updateProfile(user, { displayName: user.displayName, photoURL: avatarSrc });
        //create new user at our backend
        const createUserResponse = await callApi('/api/create-user', {
          method: 'POST',
          requiresAuth: false,
          requestData: {
            uid: user.uid,
            username: user.displayName,
            email: user.email,
            is_brand: userRole === 'brand',
            is_creator: userRole === 'creator',
            //captchaToken: captchaToken,
          }
        });
        if (createUserResponse.error) throw new Error(createUserResponse.error);
        navigate('/invite');
      }
    } catch (error) { 
      if (error.code === 'auth/popup-closed-by-user' && auth.currentUser) { navigate('/invite'); return; }
      setErrorMessage("Error with Google sign-up: " + error.message);
    } finally { setIsLoading(false); }
  }, [userRole, callApi, navigate, avatarSrc]);

  //FACEBOOK
  const facebookSignUp = useCallback(async () => {
    //check capcha
    //if (!captchaToken) { setErrorMessage('reCAPTCHA token is not ready. Please try again.'); return; }

    setIsLoading(true);
    setErrorMessage('');
    try {
      const provider = new FacebookAuthProvider();

      if (isMobileBrowser()) {
        await signInWithRedirect(auth, provider);
      } else {
        const result = await signInWithPopup (auth, provider);
        const user = result.user;
        await updateProfile(user, { displayName: user.displayName, photoURL: avatarSrc });
        //create new user at our backend
        const createUserResponse = await callApi('/api/create-user', {
          method: 'POST',
          requiresAuth: false,
          requestData: {
            uid: user.uid,
            username: user.displayName,
            email: user.email,
            is_brand: userRole === 'brand',
            is_creator: userRole === 'creator',
            //captchaToken: captchaToken,
          }
        });
        if (createUserResponse.error) { throw new Error(createUserResponse.error); }
        navigate('/invite');
      }
    } catch (error) { 
      if (error.code === 'auth/popup-closed-by-user' && auth.currentUser) { navigate('/invite'); return; }
      if (error.code === 'auth/account-exists-with-different-credential') { setErrorMessage(`Error with Facebook sign-up: this email is already registered with another signup provider`);
      } else { setErrorMessage("Error with Facebook sign-up: " + error.message); } 
    } finally { setIsLoading(false); }
  }, [userRole, callApi, navigate, avatarSrc]);
  
  //EMAIL
  const handleSignUp = useCallback(async (event) => {
    event.preventDefault();
    //check capcha
    //if (!captchaToken) { setErrorMessage('reCAPTCHA token is not ready. Please try again.'); return; }

    if (!formRef.current) { setErrorMessage('Form not found.'); return; }
    setIsLoading(true); 
    setErrorMessage('');
    const data = new FormData(formRef.current);
    const email = data.get('email');
    const password = data.get('password');
    const username = data.get('username');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(userCredential.user, { displayName: username, photoURL: avatarSrc });
      //create new user at our backend
      const createUserResponse = await callApi('/api/create-user', {
        method: 'POST',
        requiresAuth: false,
        requestData: {
          uid: userCredential.user.uid,
          username: username,
          email: email,
          is_brand: userRole === 'brand',
          is_creator: userRole === 'creator',
          //captchaToken: captchaToken,
        }
      });
      if (createUserResponse.error) throw new Error(createUserResponse.error);
      //send email and forward to verify
      await sendEmailVerification(userCredential.user);
      navigate('/email-confirmation-waiting');
    } catch (error) { 
        if (auth.currentUser) { await auth.currentUser.delete().catch(err => { console.error("Failed to rollback Firebase user creation:", err); }); }
        let errMsg = error.message;
        try { errMsg = JSON.parse(errMsg.replace(/^API call failed:\s*/, "")).error; } catch (e) {} setErrorMessage(errMsg);
    } finally { setIsLoading(false); }
  }, [userRole, callApi, navigate, avatarSrc]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundImage: theme.palette.mode === 'light' ? 'linear-gradient(180deg, rgba(206, 229, 253, 0.3), #FFF)' : 'linear-gradient(180deg, #02294F, rgba(9, 14, 16, 0))', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <Container component="main" maxWidth="xs" sx={{ height: '100vh' }}>
          <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '48px' }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: theme.spacing(15), height: theme.spacing(15) }} src={avatarSrc} alt={userRole} />
            <Typography component="h1" variant="h5">Sign Up {userRole === 'creator' ? 'as a Creator' : 'as a Brand'}</Typography>
            <RadioGroup row aria-label="user role" name="userRole" value={userRole} onChange={(e) => setUserRole(e.target.value)} sx={{ mb: 2, pt: '18px' }}>
              <FormControlLabel value="creator" control={<Radio />} label="Creator" />
              <FormControlLabel value="brand" control={<Radio />} label="Brand" />
            </RadioGroup>
            {errorMessage && <Alert severity="error" sx={{ width: '100%', mb: 2 }}>{errorMessage}</Alert>}

            {/* Social Login Buttons */}
            <Box sx={{ width: '100%', mb: 2 }}>
              <Button fullWidth variant="contained" onClick={googleSignUp} disabled={isLoading} 
                sx={{ mt: 2, mb: 1, minHeight: "50px", backgroundColor: "#fff", color: "rgba(0,0,0,0.54)", textTransform: "none", fontWeight: "bold", border: "1px solid #dadce0", "&:hover": { backgroundColor: "#f7f7f7" } }}>
                <img src={GOOGLE_LOGO} alt="Google" style={{ width: 24, height: 24, marginRight: 8, verticalAlign: "middle" }} /> Sign Up with Google
              </Button>
              <Button fullWidth variant="contained" onClick={facebookSignUp} disabled={isLoading} 
                sx={{ mt: 1, mb: 2, minHeight: "50px", backgroundColor: "#1877F2", color: "#fff", textTransform: "none", fontWeight: "bold", "&:hover": { backgroundColor: "#166FE5" } }}>
                <img src={FB_LOGO} alt="Facebook" style={{ width: 24, height: 24, marginRight: 8, verticalAlign: "middle" }} /> Sign Up with Facebook
              </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 2 }}>
              <Box sx={{ flex: 1, height: '1px', backgroundColor: 'lightgray' }} />
              <Typography sx={{ mx: 1 }}>or</Typography>
              <Box sx={{ flex: 1, height: '1px', backgroundColor: 'lightgray' }} />
            </Box>

            <Button fullWidth variant="outlined" onClick={() => setShowEmailSignUp(prev => !prev)}  sx={{ mt: 2, mb: 2, minHeight: "50px", textTransform: "none", fontWeight: "bold", 
                  border: "1px solid #dadce0", "&:hover": { backgroundColor: "#f7f7f7" } }}>  Sign Up with Email & Password</Button>
            {showEmailSignUp && (<Box component="form" noValidate ref={formRef} onSubmit={handleSignUp} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}><TextField required fullWidth id="username" label="Username" name="username" autoComplete="off" /></Grid>
                <Grid item xs={12}><TextField required fullWidth id="email" label="Email Address" name="email" autoComplete="email" value={email} onChange={(e) => setEmail(e.target.value)}/></Grid>
                <Grid item xs={12}><TextField required fullWidth name="password" label="Password" type="password" id="password" autoComplete="new-password" /></Grid>
                <Grid item xs={12}><FormControlLabel control={<Checkbox value="allowExtraEmails" color="primary" />} label="I want to receive marketing emails" /></Grid>
              </Grid>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, minHeight: "50px" }} disabled={isLoading}>Sign Up</Button>
            </Box>)}
            <Grid container justifyContent="flex-end">
                <Grid item><MuiLink component={Link} to="/signin" variant="body2">Already have an account? Sign in</MuiLink></Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default SignUp;
