import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Typography, Container, Alert } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useApiCall } from '../hooks/useApiCall.js';
import { auth } from '../service_components/firebase';

const InvitePage = ({ setIsInvited, handleDeleteAccount}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const callApi = useApiCall();
  const navigate = useNavigate();
  const theme = useTheme();
  const [error, setError] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  
  //verifies and uses invite
  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentUser = auth.currentUser;
    if (!currentUser) {  setError('User is not authenticated');  return;}

    try {
      // check recaptcha, invite code, etc.
      if (!executeRecaptcha) { setError('Recaptcha not available'); return; }
      const token = await executeRecaptcha('invite_signup');
      if (!token) { setError('Failed to execute reCAPTCHA'); return; }
      if (!inviteCode) { setError('Invite code is required!'); return; }
  
      await callApi(`/api/validate-invite?code=${encodeURIComponent(inviteCode)}`, { method: 'GET', requiresAuth: false, });
      await callApi('/api/create-user', { method: 'POST', requiresAuth: false, requestData: { uid: currentUser.uid, captchaToken: token, inviteCode: inviteCode } });

      setIsInvited(true);
      navigate('/profile');
    } catch (error) {
      let errMsg = error.message;
      try {errMsg = JSON.parse(errMsg.replace(/^API call failed:\s*/, '')).message; } catch (e) {} setError(errMsg);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box sx={{ marginTop: theme.spacing(15), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5"> Enter Your Invite Code </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            {error && <Alert severity="error" sx={{ width: '100%', mt: 1, mb: 2 }}>{error}</Alert>}
            <TextField variant="outlined" margin="normal" required fullWidth id="inviteCode" label="Invite Code" name="inviteCode" autoFocus value={inviteCode} onChange={(e) => setInviteCode(e.target.value)} />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Submit </Button>
            <Typography variant="body2" color="error" sx={{ cursor: 'pointer', mt: 2, textAlign: 'right', textDecoration: 'underline', alignSelf: 'stretch' }} onClick={handleDeleteAccount}>Delete my data</Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default InvitePage;
